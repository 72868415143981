// src/components/ScrollToTopButton.js
import React, { useState, useEffect } from 'react';

function ScrollToTopButton() {
  const [isVisible, setIsVisible] = useState(false);

  // スクロール時にボタンの表示・非表示を切り替える
  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  // 最上部にスクロールする関数
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div>
      {isVisible && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-8 right-8 bg-white text-blue-600 border border-gray-600 p-3 rounded-full shadow-md hover:bg-blue-600 hover:text-white z-40"
        >
          ▲<br />Top
        </button>
      )}
    </div>
  );
}

export default ScrollToTopButton;
