import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

function HeadLine() {
  return (
    <div>
      {/* HeadLine */}
      <Link
        smooth to="/#plans"
        className="block relative w-full bg-cover bg-center pt-24 pb-16"
        style={{
          backgroundImage: "url('/images/company_top2.jpg')",
          backgroundPosition: "center center",
        }}
      >
        {/* オーバーレイ */}
        <div className="absolute inset-0 bg-black opacity-40"></div>

        {/* コンテンツ */}
        <div
          className="relative flex flex-col h-full p-8 text-center justify-center items-center"
        >
          <h1
            className="text-white text-4xl md:text-6xl font-bold glowing-text leading-relaxed md:leading-loose" // 行間調整
          >
            <span className="block sm:inline">あなたのお店の</span>
            <span className="block sm:inline">ホームページを<br /></span>
            <span className="block sm:inline">お作りします！</span>
          </h1>
          <p className="text-white text-4xl md:text-7xl font-semibold glowing-text mt-16">
            月額 5,000円 〜
          </p>
          {/* ボタン */}
          <Link
            smooth to="/#plans"
            className="mt-8 px-6 py-2 text-white rounded-full bg-blue-600 hover:bg-blue-500 transition duration-300"
            onClick={(e) => e.stopPropagation()} // 背景リンクとの競合を防ぐ
          >
            プラン一覧へ
          </Link>
        </div>
      </Link>

      {/* Heading */}
      <section className="mt-2 py-12 px-6 bg-indigo-800 text-white text-center">
        {/* 説明文部分 */}
        <p className="text-lg glowing-text sm:text-xl mb-8">
          <span className="block sm:inline">初期費用0円・完全月額制で</span>
          <span className="block sm:inline">シンプルで見やすいサイトを提供します。</span>
          <span className="block sm:inline">面倒な作業はすべてお任せください！</span>
        </p>

        {/* サービス内容リスト */}
        <ul className="text-left glowing-text space-y-3 text-lg mx-auto max-w-md">
          <li>✅ ホームページ制作（静的ページ）</li>
          <li>✅ スマホ表示対応</li>
          <li>✅ ドメイン取得代行・設定</li>
          <li>✅ 安心・安全なURL（https://〜）を使用</li>
          <li>✅ 定期的な更新作業</li>
          <li>✅ メニューや写真の変更対応</li>
          <li>✅ 営業時間変更、テキスト修正</li>
          <li>✅ 契約期間の縛りなし、いつでも解約可能</li>
          <li>✅ 初期費用0円の安心プラン</li>
        </ul>

        {/* 注意書き */}
        <p className="text-xs md:text-sm text-gray-300 mt-8">
          ※ 大幅なデザイン変更や高度な機能追加（予約システムなど）は含まれません<br />
          ※ お支払いは12ヶ月分ご一括となりますが、ご解約時は月割りで返金いたします
        </p>
      </section>
    </div>
  );
}

export default HeadLine;
