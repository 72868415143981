import React from 'react';

function Footer() {
  return (
    <footer className="relative">
      {/* 透過背景レイヤー */}
      <div className="absolute inset-0 bg-gray-200 opacity-70"></div>

      {/* フッターのコンテンツ */}
      <div className="relative text-xs md:text-base py-2 md:py-4">
        <div className="container mx-auto text-center max-w-screen-lg">
          <p>&copy; 2025 Shuntaro Kishida. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
