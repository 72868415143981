import React from 'react';

function AboutUs() {
  return (
    <section id="about" className="container mx-auto max-w-screen-lg pt-36 pb-12">
      <h2 className="font-bold text-5xl md:text-7xl mb-6 text-center">About Me</h2>
      <div className="p-4 bg-white shadow-md rounded-md">
        <p className="p-6 mb-4 font-semibold md:text-lg tracking-wider leading-relaxed text-justify">
          ホームページの企画から、制作、運用までの全行程の請負業務を行なっております。全行程を一人で行なっておりますので、迅速かつ一貫性のある対応をさせていただきます。テンプレート形式のシンプルなホームページを基本とし、メンテナンス性に優れ、定期的な情報更新をスムーズに行える、ビジネス的に実用性のあるホームページをご提供いたします。ご希望があれば、個性的で動きのある唯一無二のホームページまで幅広く対応しております（※プラン外サービスとなります）。お気軽にお問い合わせください。
        </p>

        {/* 代表者情報表 */}
        <table className="table-auto w-full text-xs md:text-base text-left border border-gray-300 mb-4">
          <tbody>
            <tr>
              <th className="px-4 py-2 bg-gray-600 text-white border border-gray-300">代表者名</th>
              <td className="font-semibold px-4 py-2 border border-gray-300">岸田 俊太郎</td>
            </tr>
            <tr>
              <th className="px-4 py-2 bg-gray-600 text-white border border-gray-300">Programming Skills</th>
              <td className="px-4 py-2 border border-gray-300">Ruby (Ruby on Rails), PHP (Laravel), React.js, WordPress</td>
            </tr>
            <tr>
              <th className="px-4 py-2 bg-gray-600 text-white border border-gray-300">Job Skills</th>
              <td className="px-4 py-2 border border-gray-300">IT系企業インサイドセールス</td>
            </tr>
          </tbody>
        </table>

        {/* 画像ギャラリー */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
          <img
            src="/images/me.jpeg"
            alt="Chef"
            className="w-full h-72 object-cover rounded-md"
          />
          <img
            src="/images/pc.jpeg"
            alt="Exterior"
            className="w-full h-72 object-cover rounded-md"
          />
          <img
            src="/images/note.jpeg"
            alt="Interior"
            className="w-full h-72 object-cover rounded-md"
          />
        </div>
      </div>
    </section>
  );
}

export default AboutUs;
