import React from 'react';

const demoSites = [
  {
    id: 1,
    image: '/images/salon3.png',
    name: '美容室様向けプラン',
    description: '落ち着いた雰囲気と洗練されたデザインの美容室様向けサイト。',
    link: 'https://shuntarokishidademo001.site',
    plan: {
      price: '月額 5,000円（税込）',
      features: ['✅ 更新月1回まで無料', '✅ シンプルでスタイリッシュなデザイン'],
    },
  },
  {
    id: 2,
    image: '/images/restaurant3.png',
    name: '洋風飲食店向けプラン',
    description: 'モダンでシンプルな洋風レストラン様向けサイト。',
    link: 'https://shuntarokishidademo002.site',
    plan: {
      price: '月額 9,000円（税込）',
      features: ['✅ 更新月2回まで無料', '✅ ＋メニュー詳細ページ対応', '✅ シンプルでスタイリッシュなデザイン'],
    },
  },
  {
    id: 3,
    image: '/images/washoku3.png',
    name: '和風飲食店様向けプラン',
    description: '伝統的な和風デザインを取り入れた飲食店様向けサイト。',
    link: 'https://shuntarokishidademo005.site',
    plan: {
      price: '月額 9,000円（税込）',
      features: ['✅ 更新月2回まで無料', '✅ ＋メニュー詳細ページ対応', '✅ シンプルでスタイリッシュなデザイン'],
    },
  },
  {
    id: 4,
    image: '/images/cafe3.png',
    name: 'カフェ店舗様向けプラン',
    description: '温かみのあるデザインで、居心地の良さを表現したカフェ向けサイト。',
    link: 'https://shuntarokishidademo003.site',
    plan: {
      price: '月額 7,000円（税込）',
      features: ['✅ 更新月2回まで無料', '✅ シンプルでスタイリッシュなデザイン'],
    },
  },
  {
    id: 5,
    image: '/images/bar3.png',
    name: 'BAR店舗様向けプラン',
    description: '大人の雰囲気を演出するBAR向けデザインサイト。',
    link: 'https://shuntarokishidademo004.site',
    plan: {
      price: '月額 5,000円（税込）',
      features: ['✅ 更新月1回まで無料', '✅ ダークテーマデザイン', '✅ シンプルでスタイリッシュなデザイン'],
    },
  },
  {
    id: 5,
    image: '/images/patisserie.png',
    name: '販売店様向けプラン',
    description: 'ポップさと商品閲覧に卓越した物販店向けサイト。',
    link: 'https://shuntarokishidademo006.site',
    plan: {
      price: '月額 8,000円（税込）',
      features: ['✅ 更新月2回まで無料', '✅ ＋メニュー詳細ページ対応', '✅ シンプルでポップなデザイン'],
    },
  },
];

const Plans = () => {
  return (
    <section id="plans" className="container mx-auto max-w-screen-lg pt-36 pb-12">
      <h2 className="font-bold text-5xl md:text-7xl mb-6 text-center">Plans</h2>
      {demoSites.map((site, index) => (
        <div
          key={site.id}
          className={`flex flex-col md:flex-row ${index % 2 === 0 ? 'md:flex-row-reverse' : ''} items-center bg-white rounded-lg shadow-md p-6 mb-8`}
        >
          <img
            src={site.image}
            alt={site.name}
            className="w-full md:w-1/2 object-cover rounded-md mb-4 md:mb-0"
          />
          <div className="md:w-1/2 text-center p-4">
            <h3 className="inline-block border-b border-gray-600 text-xl font-semibold mb-2">◉ {site.name}</h3>
            <p className="text-sm md:text-base text-gray-700 mb-4">{site.description}</p>
            <p className="text-lg font-bold mb-2">{site.plan.price}</p>
            <ul className="text-sm md:text-base text-gray-700 mb-4 space-y-2 text-left md:px-12">
              {site.plan.features.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
            <a
              href={site.link}
              target="_blank"
              rel="noopener noreferrer"
              className="bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-500 transition"
            >
              デモサイトへ
            </a>
          </div>
        </div>
      ))}
    </section>
  );
};

export default Plans;
