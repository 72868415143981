import React from 'react';
import Plans from '../contents/Plans';
import AboutUs from '../contents/AboutUs';
import Access from '../contents/Access';
import ScrollToTopButton from './ScrollToTopButton';
import HeadLine from '../contents/HeadLine';

function Home() {
  return (
    <div className="relative">
      <HeadLine />
      {/* コンテンツ */}
      <div id="heading" className="p-4 mt-screen relative z-10 pb-16">
        <Plans />
        <AboutUs />
        <Access />
      </div>

        {/* ▲Topボタン */}
        <ScrollToTopButton />
      </div>
  );
}

export default Home;
