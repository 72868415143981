import React, { useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="fixed w-full z-50 top-0">
      {/* ヘッダーの背景レイヤー */}
      <div className="absolute inset-0 bg-gray-200 opacity-90"></div>

      {/* ヘッダーのメインコンテンツ */}
      <div className="relative container mx-auto flex justify-between items-center max-w-screen-lg px-4 py-1 md:py-4">
        <div>
          <Link smooth to="/#top" className="text-xl md:text-4xl font-medium md:font-semibold no-underline hover:no-underline">
            Shuntaro Kishida
          </Link>
        </div>
        <button
          className="md:hidden text-4xl focus:outline-none"
          onClick={toggleMenu}
        >
          {isOpen ? '✕' : '☰'}
        </button>
        <nav className="hidden md:flex">
          <Link smooth to="/#plans" className="mr-4 no-underline hover:underline">Plans</Link>
          <Link smooth to="/#about" className="mr-4 no-underline hover:underline">About Me</Link>
          <Link smooth to="/#access" className="mr-4 no-underline hover:underline">Access</Link>
        </nav>
      </div>
      {isOpen && (
        <div className="fixed inset-0 bg-blue-700 opacity-95 flex flex-col justify-center items-center glowing-text text-white text-4xl font-semibold">
          <button
            className="absolute top-1 right-4 text-4xl focus:outline-none"
            onClick={toggleMenu}
          >
            ✕
          </button>
          <Link smooth to="/#plans" className="my-4 no-underline hover:underline" onClick={toggleMenu}>Plans</Link>
          <Link smooth to="/#about" className="my-4 no-underline hover:underline" onClick={toggleMenu}>About Me</Link>
          <Link smooth to="/#access" className="my-4 no-underline hover:underline" onClick={toggleMenu}>Access</Link>
        </div>
      )}
    </header>
  );
}

export default Header;
